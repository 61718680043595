<template>
  <div class="col-md-4 offset-md-4 mt-5">
    <b-card class="border border-primary text-center">
      <b-img src="/logo.png" fluid alt="FINavigator Logo" class="w-50" />
      <b-card-body class="col-lg-8 offset-lg-2">
        <h2 class="mb-4 text-primary">Reset Password</h2>
        <b-input-group class="mb-4">
          <input
            type="password"
            class="form-control rounded"
            :class="{
              'is-invalid': $v.password.$error,
              'is-valid': !$v.password.$invalid
            }"
            placeholder="Password"
            v-model="password"
            @blur="$v.password.$touch()"
          />
          <b-form-invalid-feedback v-if="!$v.password.required"
            >Password can't be blank</b-form-invalid-feedback
          >
        </b-input-group>
        <b-input-group>
          <input
            type="password"
            class="form-control rounded"
            :class="{
              'is-invalid': $v.repeatPassword.$error,
              'is-valid': !$v.repeatPassword.$invalid
            }"
            placeholder="Repeat Password"
            v-model="repeatPassword"
            @blur="$v.repeatPassword.$touch()"
          />
          <b-form-invalid-feedback v-if="!$v.repeatPassword.sameAsPassword"
            >Password must be same.</b-form-invalid-feedback
          >
        </b-input-group>
        <b-row class="mb-4 mt-4">
          <b-col cols="6" class="text-left align-bottom">
            <router-link :to="{ path: '/sign_in' }">Sign In</router-link>
          </b-col>
          <b-col cols="6" class="text-right">
            <b-button
              variant="primary"
              class="px-4 login-btn"
              :disabled="$v.$invalid"
              @click="reset"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import Vue from 'vue'

export default {
  name: 'NewPassword',
  data: () => ({
    password: '',
    repeatPassword: ''
  }),
  validations: {
    password: {
      required
    },
    repeatPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    reset() {
      this.$http
        .post('/api/update_password', {
          pass: this.password,
          rep_pass: this.repeatPassword,
          id: this.$route.params.id,
          token: this.$route.query.token
        })
        .then(() => {
          Vue.toasted.show('Password is successfully reset.', {
            icon: 'key',
            type: 'success'
          })
          this.$router.push('/sign_in')
        })
        .catch(() => {
          Vue.toasted.show(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$http
        .get(`api/passwords/${vm.$route.params.id}/check_token`, {
          params: { token: vm.$route.query.token }
        })
        .then(() => {})
        .catch(error => {
          if (error.response && error.response.status === 403) {
            Vue.toasted.show(
              'Email token invalid/expired. Please contact admin.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }

          vm.$router.push({ name: 'SignIn', params: { silent: true } })
        })
    })
  }
}
</script>
